/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
import React, {
	useState, useEffect, useRef, useCallback, useContext
} from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';
import { navigate } from 'gatsby-link';
import { GatsbyImage } from 'gatsby-plugin-image';
import renderIcon from '../../utils/renderIcon';
import useNavigationQuery from './useNavigationQuery';
import { SearchContext } from '../../context/search-context';

import Search from '../Search';
import Link from '../Link';
import CartButton from '../CartFlyout/CartButton';
import SubMenu from '../SubMenu';
import NavMobileMenu from '../NavMobileMenu';

import { addKlaviyoProfileIdToUrl } from '../../utils/klaviyo';

const ns = `navigation`;

const Navigation = ({ pathname, isPreHeaderBarHidden, hasPreHeader }) => {
	const {
		contentfulHeader: {
			menuLinksDesktop,
			menuLinksMobile,
		},
		contentfulGlobalSettings: {
			logo,
		},
	} = useNavigationQuery();
	const {
		isSearchOpen,
		handleSearchClose,
		handleSearchToggle,
	} = useContext(SearchContext);
	const [currentActiveSubMenu, setCurrentActiveSubMenu] = useState(null);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	const locationRef = useRef(null);
	const navContainerRef = useRef(null);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--search-open`]: isSearchOpen,
	});

	/*--------------------------------
	Event Handlers
	--------------------------------*/
	const showSubMenu = e => {
		if (e.target.dataset.index) {
			const currentIndex = (e.target.dataset.index);

			handleSearchClose();

			setCurrentActiveSubMenu(currentIndex);
		} else {
			setCurrentActiveSubMenu(null);
		}
	};

	const hideSubMenu = () => {
		setCurrentActiveSubMenu(null);
	};

	const toggleMobileMenu = useCallback(() => {
		handleSearchClose();
		setIsMobileMenuOpen(!isMobileMenuOpen);
	}, [handleSearchClose, isMobileMenuOpen]);

	const handleSubMenus = useCallback(() => {
		if (currentActiveSubMenu !== null) {
			const navContainer = navContainerRef.current;
			const currentSubmenu = document.querySelector(`.sub-menu__container--${ currentActiveSubMenu }`);
			const currentLink = navContainer.querySelector(`.link[data-index="${ currentActiveSubMenu }"]`);

			if (currentSubmenu) {
				currentSubmenu.classList.add('active');
				navContainer.classList.add('submenu-active');
				currentLink.classList.add('active');
			}
		} else {
			const allSubMenus = document.querySelectorAll(`.sub-menu__container`);
			const navContainer = navContainerRef.current;

			navContainer.classList.remove('submenu-active');

			setCurrentActiveSubMenu(null);

			allSubMenus.forEach(subMenu => {
				subMenu.classList.remove('active');
			});
		}
	}, [currentActiveSubMenu]);

	/**
	 * Custom middleware function that check if the location has changes and
	 * triggers events accordingly.
	 */
	const handleNavigationMiddleware = useCallback(() => {
		if (!locationRef.current) {
			locationRef.current = pathname;
		} else if (locationRef.current !== pathname) {
			if (isSearchOpen) {
				handleSearchClose();
			}
			if (isMobileMenuOpen) {
				toggleMobileMenu();
			}

			locationRef.current = pathname;
		}
	}, [pathname, isSearchOpen, isMobileMenuOpen, handleSearchClose, toggleMobileMenu]);

	const handleAccountButtonClick = () => {
		const accountUrl = `https://${ process.env.GATSBY_SHOPIFY_STORE_URL }/account`;
		const accountUrlWithKlaviyoId = addKlaviyoProfileIdToUrl(accountUrl);
		navigate(accountUrlWithKlaviyoId);
	}

	/*--------------------------------
	useEffect
	--------------------------------*/
	useEffect(() => {
		handleSubMenus();
		handleNavigationMiddleware();
	}, [handleSubMenus, handleNavigationMiddleware]);

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__container`}>
				<div className={`${ ns }__menu`}>
					<button
						className={`hamburger hamburger-squeeze ${ isMobileMenuOpen ? 'is-active' : '' }`}
						type={'button'}
						onClick={toggleMobileMenu}
					>
						<span className={`visually-hidden`}>Show/Hide Mobile Menu</span>
						<span className={'hamburger-box'}>
							<span className={'hamburger-inner'} />
						</span>
					</button>
				</div>
				<div className={`${ ns }__logo-container`}>
					{logo.image && logo.link && (
						<Link to={logo.link}>
							<GatsbyImage alt={logo.altText} image={logo?.image.gatsbyImageData} />
						</Link>
					)}
				</div>
				<nav ref={navContainerRef} className={`${ ns }__nav`}>
					{menuLinksDesktop?.map((menuLink, index) => {
						return (
							<Link
								key={uniqueId('pbg-')}
								to={menuLink.url}
								variant={'white'}
								data-index={menuLink.typename === 'ContentfulElementDropdownLink' ? index : ''}
								onMouseEnter={showSubMenu}
								onFocus={showSubMenu}
								disabled={menuLink.typename === 'ContentfulElementDropdownLink'}
							>
								{menuLink.text || menuLink.label}
								{menuLink.typename === 'ContentfulElementDropdownLink' ? renderIcon('cheveron-down') : ''}
							</Link>
						);
					})}
				</nav>
				<div className={`${ ns }__right-nav`}>
					{isSearchOpen && (
						<Search isPreHeaderBarHidden={isPreHeaderBarHidden} hasPreHeader={hasPreHeader} />
					)}
					<button className={'search-button link'} onClick={handleSearchToggle}>
						{renderIcon('search')}
					</button>
					<button className={'account-button'} onClick={handleAccountButtonClick}>
						{renderIcon('account')}
					</button>
					<CartButton />
				</div>
			</div>

			<SubMenu
				menuLinks={menuLinksDesktop}
				isActive={currentActiveSubMenu !== null}
				hideSubMenu={hideSubMenu}
			/>

			<NavMobileMenu
				isMenuOpen={isMobileMenuOpen}
				isPreHeaderBarHidden={isPreHeaderBarHidden}
				menuLinksMobile={menuLinksMobile}
				toggleMobileMenu={toggleMobileMenu}
			/>
		</div>
	);
};

export default Navigation;
