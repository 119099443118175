/**
 * Shopify Storefront API url
 */
export const STOREFRONT_API_URL = `https://${ process.env.GATSBY_SHOPIFY_STORE_URL }/api/${process.env.GATSBY_SHOPIFY_API_VERSION || '2023-07'}/graphql.json`;
// export const STOREFRONT_API_URL = `https://${ process.env.GATSBY_SHOPIFY_STORE_URL }/api/2022-04/graphql.json`;

/**
 * Shopify Storefront API access token
 */
export const STOREFRONT_API_ACCESS_TOKEN = process.env.GATSBY_STOREFRONT_ACCESS_TOKEN;

export const REQUIRES_PRODUCT_ID_KEY = '_requires_product_id';

export const REQUIRES_VARIANT_ID_KEY = '_requires_variant_id';

export const ADD_RICH_TEXT_SCRIPTS_TO_HEAD = Boolean(process.env.GATSBY_ADD_RICH_TEXT_SCRIPTS_TO_HEAD === 'true');

export const KLAVIYO_COMPANY_ID = process.env.GATSBY_KLAVIYO_COMPANY_ID;

export const COMING_SOON_LIST_ID = 'coming_soon_klaviyo_list_id';
export const COMING_SOON_LABEL = 'COMING SOON - JOIN WAITLIST';

export const SOLD_OUT = 'sold_out';

export const REBUY_API_KEY = process.env.GATSBY_REBUY_API_KEY;
export const REBUY_CUSTOM_DATA_SOURCE_ID = process.env.GATSBY_REBUY_CUSTOM_DATA_SOURCE_ID;

/**
 * Enum
 */
export const freeGiftStrategyEnum = Object.freeze({
	disabled: 'Disabled',
	subtotalThreshold: 'Subtotal Threshold',
	qualifyingProductThreshold: 'Qualifying Product Threshold',
	qualifyingCollectionThreshold: 'Qualifying Collection Threshold'
});

export const freeSamplesStrategyEnum = Object.freeze({
	disabled: 'Disabled',
	subtotalThreshold: 'Subtotal Threshold',
	qualifyingProductThreshold: 'Qualifying Product Threshold',
	qualifyingCollectionThreshold: 'Qualifying Collection Threshold'
});
